header#master-header {
  background-color: var(--color-palette-dark-blue);
  border-bottom: 2px solid var(--color-palette-orange);
  padding: calc(calc(10 / 16) * 1rem) calc(calc(20 / 16) * 1rem);
}
header#master-header .master-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-areas: "logo mnav search";
}
header#master-header #hamburger-menu {
  display: none;
}
header#master-header #header-logo {
  grid-area: logo;
  color: white;
  text-decoration: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
header#master-header #main-menu-container {
  grid-area: mnav;
}
header#master-header #main-menu-container ul {
  list-style: none;
}
header#master-header #main-menu-container ul li {
  display: inline-block;
  margin: 0 10px;
}
header#master-header #main-menu-container ul li a {
  color: white;
  text-decoration: none;
}
header#master-header #header-search-bar {
  grid-area: search;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header#master-header #header-search-bar input {
  border: 1px solid white;
  background-color: transparent;
  font-size: 16px;
  padding: 5px;
  color: white;
  display: block;
}
header#master-header #header-search-bar input::placeholder {
  color: white;
  font-size: 16px;
}
#mobile-menu {
  display: none;
}
@media screen and (max-width: 880px) {
  header#master-header .master-container {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "logo bttn";
  }
  header#master-header #hamburger-menu {
    display: block;
    width: fit-content;
    margin-left: auto;
    background-color: transparent;
    color: white;
    font-size: 24px;
    grid-area: bttn;
    border: 0;
    cursor: pointer;
    padding: 10px;
  }
  header#master-header #header-search-bar,
  header#master-header #main-menu-container {
    display: none;
  }
  #mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    background-color: var(--color-palette-dark-blue);
    height: 100%;
    padding: 10px;
    z-index: 99;
    transition: 0.5s;
  }
  #mobile-menu.active {
    left: 0;
  }
  #mobile-menu #mobile-menu-container ul {
    list-style: none;
    padding: 0;
  }
  #mobile-menu #mobile-menu-container ul li {
    margin: 20px 0;
  }
  #mobile-menu #mobile-menu-container ul li a {
    color: white;
    text-decoration: none;
  }
  #mobile-menu #mobile-search-bar {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  #mobile-menu #mobile-search-bar input {
    border: 1px solid white;
    background-color: transparent;
    font-size: 16px;
    padding: 5px;
    color: white;
    display: block;
  }
  #mobile-menu #mobile-search-bar input::placeholder {
    color: white;
    font-size: 16px;
  }
}
