@import (reference) "./core/functions.less";
@import (reference) "./core/standards.less";


    header#master-header{
        background-color:@color-palette-dark-blue-var;
        border-bottom:2px solid @color-palette-orange-var;
        padding: .px-to-rem(10)[@rem] .px-to-rem(20)[@rem];

        .master-container{
            display: grid;
            grid-template-columns: 1fr 1.5fr 1fr;
            grid-template-areas: "logo mnav search";
        }

        #hamburger-menu{
            display:none;
        }

        #header-logo{
            grid-area:logo;
            color:white;
            text-decoration: none;
            display:flex;
            flex-flow:column nowrap;
            align-items: center;
            justify-content: center;
            font-size:24px;
            text-transform: uppercase;
            letter-spacing:2px;
        }

        #main-menu-container{
            grid-area:mnav;
            ul{
                list-style:none;
                li{
                    display:inline-block;
                    margin:0 10px;
                    a{
                        color:white;
                        text-decoration: none;
                    }

                }

            }

        }

        #header-search-bar{
            grid-area:search;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            input{
                border:1px solid white;
                background-color:transparent;
                font-size:16px;
                padding:5px;
                color:white;
                display:block;
                &::placeholder {
                    color: white;
                    font-size:16px;
                }
            }

        }

    }

    #mobile-menu{
        display:none;
    }

    @media screen and (max-width:880px){

        header#master-header{

            
            .master-container{
                grid-template-columns: 3fr 1fr;
                grid-template-areas:"logo bttn";
            }

            #hamburger-menu{
                display:block;
                width:fit-content;
                margin-left:auto;
                background-color:transparent;
                color:white;
                font-size:24px;
                grid-area:bttn;
                border:0;
                cursor:pointer;
                padding:10px;
            }

            #header-search-bar,#main-menu-container{
                display:none;
            }
        }

        #mobile-menu{
            display:block;
            position:fixed;
            top:0;
            left:-250px;
            width:250px;
            background-color:@color-palette-dark-blue-var;
            height:100%;
            padding:10px;
            z-index:99;
            transition:.5s;

            &.active{
                left:0;
            }

            #mobile-menu-container{
                
                ul{
                    list-style:none;
                    padding:0;
                    li{
                        margin:20px 0;
                        a{
                            color:white;
                            text-decoration: none;
                        }

                    }

                }
            }

            #mobile-search-bar{
                position:absolute;
                bottom:20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
    
                input{
                    border:1px solid white;
                    background-color:transparent;
                    font-size:16px;
                    padding:5px;
                    color:white;
                    display:block;
                    &::placeholder {
                        color: white;
                        font-size:16px;
                    }
                }
    
            }
        }

    }
    